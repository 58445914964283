import {
  Box,
  Divider,
  Flex,
  List,
  ListItem,
  Menu,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import logo from "../../../assets/img/HapiMeetsLogoScroll.svg";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    document.body.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Menu>
        <Flex
          style={{ padding: "10px" }}
          minH={"80px"}
          py={{ base: 2 }}
          px={{ base: 10 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "left", md: "space-between" }}
            alignItems="center"
            className="max-withs-frame"
            ml={{ base: "2%", md: "80px" }}
          >
            <>
              <Link to="/">
                <img src={logo} alt="logo" style={{ width: "150px" }} />
              </Link>
            </>
            <Flex alignItems="center">
              <Flex
                display={{ base: "none", md: "flex" }}
                ml={10}
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  spacing={7}
                  mr={{ base: "0px", md: "0px" }}
                  ml={{ base: "0px", md: "0px" }}
                  alignItems="center"
                ></Stack>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" borderWidth="1px" />
      </Menu>
      <Box className="terms-of-use">
        <Box
          mx="auto"
          maxWidth="1024px"
          width="100%"
          bgColor="#fff"
          className="p-frame"
          mt={10}
        >
          <Box>
            <Text className="h1" pt={10} pl={5}>
              Privacy Policy
            </Text>
          </Box>
          <Box className="max-withs-frame" padding="10px" marginTop="24px">
            <Box padding="10px">
              <Box>
                <Text className="sub1" pb={10}>
                  Your privacy is of the utmost importance at HapiMeets!
                </Text>
                <Text className="para1">
                  We have developed this policy to ensure that your data is
                  protected when you use HapiMeets application.
                </Text>
                <Text className="para1" pt="24px">
                  A privacy policy is a document that sets out how we process
                  user data in our application. From the Privacy Policy, you can learn
                  what information we collect and how, how we use this information, with
                  whom we share it, how you can access or change it, and how we protect information.
                </Text>
                <Text className="para1" pt="24px">
                  We value your time and attention and try to convey information in the most simple words.
                </Text>
                <Text className="para1" pt="24px">
                  By accessing and using the HapiMeets application, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy.
                </Text>
                <Text className="para1" pt="24px">
                  This Privacy Policy applies from August 20, 2024.
                </Text>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  Special position.
                </Text>
              </Box>
              <Box className="para1">
                This privacy policy applies to the HapiMeets application, which allows its users to provide and consume services such as communication or business communication, providing and receiving advice, training, as well as any services that can be provided through communication, including correspondence in messenger or live communication via video chat.
              </Box>

              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  1. Who we are
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                HapiMeets application are managed by HAPIMEETS PTE. LTD
              </Box>
              <Box className="para1">
                180 CECIL STREET #12-01 BANGKOK BANK BUILDING SINGAPORE 069546
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  2. Where This Privacy Policy Applies
                </Text>
              </Box>
              <Box className="para1">
                This Privacy Policy applies to HapiMeets application (app). This Policy is a legally binding agreement between you ("User", "you" or "your") and HapiMeets PTE.LTD ("HapiMeets", "we", "us" or "our").
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  3. Information We Collect
                </Text>
              </Box>
              <Box className="para1" mb="24px">
                We collect information that you provide when you register a profile and use HapiMeets application, as well as application services, so that you can find the right person for meaningful communication, consultation or tutoring or other similar services implemented in HapiMeets in the most comfortable way for you.
              </Box>
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
                lineHeight="30px"
              >
                <Text className="sub1" pt={10} pb={5} letterSpacing="-0.5px">
                  Information you give us includes:
                </Text>
                <Box className="para1" pb={5}>
                  When you create an account, you provide us with your email address.
                </Box>
                <Box className="para1" pb={5}>
                  Also, when you first enter the application, you indicate your geolocation.
                </Box>
                <Box className="para1" pb={5}>
                  When you complete your profile you may also need to upload a photo from your device (or take a new one) and write details about you on your bio.
                  To add images you need to allow us access to your camera or photo album.
                </Box>
                <Box className="para1" pb={5}>
                  You can also share information about your gender, date of birth, your skills or skills which you are interested in.
                </Box>
                <Box className="para1" pb={5}>
                  You are in control of your profile and you can correct or update information about yourself in your profile settings.
                </Box>
                <Box className="para1" pb={5}>
                  After you create a profile, we automatically track some information, such as your IP address.
                </Box>
                <Box className="para1" pb={5}>
                  When you contact support, we collect the information you provide in the process of interaction.
                </Box>
                <Box className="para1" pb={5}>
                  If necessary, we also process your chats with other users.
                </Box>
              </Box>
              <br />
              <br />
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
              >
                <Text className="sub1" pt={10} pb={5}>
                  Information from other sources:
                </Text>
                <Box className="para1" pb={5}>
                  In the event that you create or log into your account through Google, Apple or Facebook - we receive information from these social networks  (such as email address).
                </Box>
                <Box className="para1" pb={5}>
                  Other users may also provide information about you, for example, when contacting support or when using our services.
                </Box>
                <Box className="para1" pb={5}>
                  We also store information about your profile settings, for example, the interface language, your notification settings, your photo visibility settings to make it easier for you to use the app.
                </Box>
                <Box className="para1" pb={10}>
                  We store your contact list, chat history, favorites and ignores, ratings, likes and dislikes, messages you sent and received, and other information about your activities in the app to help you communicate better using our app.
                </Box>
              </Box>
              <Box className="para1" pt={10} pb={5}>
                We also collect the information with your consent such as:
              </Box>
              <Box className="para1" pb={5}>
                Geolocation data such as latitude and longitude.
              </Box>
              <Box className="para1" pb={5}>
                Any other materials you willingly submit to us such as articles, images, feedback, etc.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  4. How We Use Information
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                We use your information to provide services to you. And also to continuously improve them and make the use of the service as convenient as possible for you:
              </Box>

              <List styleType="disc" pl={8} pb={10}>
                <ListItem className="para1">
                  We use your personal information to manage your account and provide our services;
                </ListItem>
                <ListItem className="para1">
                  To provide you with customer support and respond to your inquiries.
                </ListItem>
                <ListItem className="para1">
                  To help you connect with other users;
                </ListItem>
                <ListItem className="para1">
                  Show members’ profiles to one another.
                </ListItem>
                <ListItem className="para1">
                  To analyze and improve our services for you.
                </ListItem>
              </List>
              <Box className="para1" pb={10}>
                We also use information about you to ensure your safety. And in order to prevent, detect and combat fraud and other illegal or unauthorized activities.
                And also to ensure legal compliance.
              </Box>
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
                pt={5}
              >
                <Text className="sub1" pt={5} pb={5}>
                  Agreement:
                </Text>
                <Box className="para1" pb={3}>
                  If you provide us with information that may be considered proprietary or confidential in certain jurisdictions, you consent to the processing of that information in accordance with this Privacy Policy.
                </Box>
                <Box className="para1" pb={10}>
                  You can withdraw your consent, for example by changing some of your profile settings, or by deleting data from your content. Also, you can always withdraw your consent by sending a request to the contacts listed at the end of the Policy.
                </Box>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={10}>
                  5. How we share information
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                In HapiMeets application, you can easily create new connections in order to gain or transfer knowledge in various fields. When using the app, you should be aware that any information published will be publicly available to its users and our service departments. You can also use chat, which allows you to chat with other users.
              </Box>
              <Box className="para1" pb={5}>
                We recommend and encourage you (and all of our users) to think about the information they disclose about themselves. We do not recommend that you provide email addresses, URLs, messenger data, phone numbers, full names and addresses, credit card information, national identification numbers, driver's license information, or other sensitive information in your profile.
              </Box>
              <Box className="para1" pb={5}>
                Remember that it is forbidden to use the personal data of other users for commercial purposes, for spam, it is also forbidden to harass other users or threaten. HapiMeets reserves the right to block/delete (suspend the provision of services) a user's account at any time for illegal use of personal data of other users or for any other violation of our terms.
              </Box>
              <Box className="para1" pb={5}>
                We may partner with third parties to serve our application (and the data it contains) and our customers, as well as for advertising and marketing and security purposes. In the case of transferring information to our partners, it is transferred in an unreadable, hashed form.
              </Box>
              <Box className="para1" pb={5}>
                We may share information with law enforcement authorities when required to do so by law or to protect legal rights.
              </Box>
              <Box className="para1" pb={5}>
                We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioral data, geolocation in de-identified form).
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  6. Terms of data storage and deletion
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                HapiMeets is guided by the provisions set forth in the ISO / IEC 27001 standard. When working with personal data received from our clients (such as namе and phone number), we process and store them using software certified by ISO 27001.
              </Box>
              <Box className="para1" pb={5}>
                We keep your personal information for as long as it is necessary for the purposes specified in section 4 of this Policy, as well as in accordance with the law.
              </Box>
              <Box className="para1" pb={5}>
                If you decide to stop using the HapiMeets services, we will automatically close your account if you are inactive for two years. After your account is closed, we will delete your personal information as follows:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  for 3 months after closing an account and for 12 months in case of account blocking, we store information for security purposes, as well as the safety of our members in case it may be necessary to investigate illegal or malicious activities.
                </ListItem>
              </List>

              <Box className="para1" pb={5}>
                After this period, your data is deleted and limited information is stored, such as:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  "traffic data" for one year to comply with legal data retention obligations.
                </ListItem>
              </List>
              <Box className="para1" pb={5}>
                We also keep records of consents given to us by members for five years to ensure compliance with applicable laws.
              </Box>
              <Box className="para1" pb={5}>
                We retain limited information based on our legitimate interests for a period of 5 years in order to make effective decisions to serve our customers and to protect ourselves in the event of a claim:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  customer service records and supporting data
                </ListItem>
                <ListItem className="para1">
                  inaccurate download/purchase location.
                </ListItem>
              </List>
              <Box className="para1" pb={5}>
                Profile data is stored for 1 year in the event of legal proceedings, in order to establish, exercise or defend legal claims.
              </Box>
              <Box className="para1" pb={5}>
                We also store the data of members who have been banned from using the applications in order to prevent the creation of a new account.
              </Box>
              <Box
                bg="#E8EAED"
                pt={5}
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
              >
                <Text className="sub1" pt={5} pb={5}>
                  Important:
                </Text>
                <Box className="para1" pb={10}>
                  We retain information based on our legitimate interest when there is an unresolved or potential issue, claim or dispute that requires us to retain information (for example, if we receive a valid subpoena or request to retain data).
                </Box>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  7. Your rights
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                You can change your profile information at any time. We strongly recommend that you change your password periodically to help reduce the risk of unauthorized access to your account. If you have any question on those tools and settings, please contact our support team for help.
              </Box>
              <Box className="para1" pb={5}>
                Uninstall. You can stop all information collection by an app by uninstalling it using the standard uninstall process for your device. Remember that uninstalling an app does NOT close your account. To close your account, please use the corresponding functionality on the service.
              </Box>
              <Box className="para1" pb={5}>
                You can also change the visibility of your profile using the visibility settings in the profile settings menu.
              </Box>
              <Box className="para1" pb={5}>
                If you have any questions about the information that we have or a request to change it or the need to delete it, you can always contact us at the contacts listed at the end of this provision.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  8. Changes to the Privacy Policy
                </Text>
              </Box>
              <Box className="para1">
                Due to the fact that we work daily to improve our service and all related provisions and documents, we may make changes to this privacy policy. We will notify you if the document is updated.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  9. To contact us
                </Text>
              </Box>
              <Box className="para1">
                You can always contact us by sending an email to <span
                style={{color: "#0055C1"}}>support@hapimeets.com</span>
              </Box>
              <Box className="para1" pb={10}>
                Also, our users always have access to chatting applications. Just go to "Messages" to write to Support.
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PrivacyPolicy;
